//import { axios } from 'vue/types/umd';
import { app, apiURL } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useMessageTypesStore = defineStore('messageTypesStore', {
  state: () => ({
    messageTypes: [] as any[]
  }),
  actions: {
    async fetchMessageTypes() {
      if (this.messageTypes.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/messageTypes')
          this.messageTypes = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
